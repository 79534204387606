import state from './moduleFinePrintManagementState.js'
import mutations from './moduleFinePrintManagementMutations.js'
import actions from './moduleFinePrintManagementActions.js'
import getters from './moduleFinePrintManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

