<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Fine Print</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-box"
          icon-no-border
          label="Fine Print Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import moduleFinePrintManagement from "@/store/fine-print-management/moduleFinePrintManagement.js";

export default {
  name: "FinePrintAdd",

  metaInfo: {
    title: "Add Fine Print",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data: () => ({
    payload: {
      name: ""
    }
  }),

  methods: {
    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);

      this.$store
        .dispatch("finePrintManagement/storeFinePrint", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/fine-prints");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!moduleFinePrintManagement.isRegistered) {
      this.$store.registerModule(
        "finePrintManagement",
        moduleFinePrintManagement
      );
      moduleFinePrintManagement.isRegistered = true;
    }
  }
};
</script>

<style></style>
