import axios from '@/axios.js'

export default {
  fetchFinePrints ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('fine-print')
        .then(({data: response}) => {
          commit('SET_FINE_PRINTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchFinePrint (context, finePrintId) {
    return new Promise((resolve, reject) => {
      axios.get(`fine-print/${finePrintId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeFinePrint (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('fine-print', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateFinePrint (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`fine-print/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyFinePrint ({ commit }, finePrintId) {
    return new Promise((resolve, reject) => {
      axios.delete(`fine-print/${finePrintId}`)
        .then((response) => {
          commit('REMOVE_RECORD', finePrintId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
